import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
	useParams,
	useRouteError
} from '@remix-run/react'
import type { LinksFunction } from '@remix-run/node'

import Navbar from './components/navbar'
import { WebHeader } from './components/ui/page-header'

import './styles/tailwind.css'
import './styles/global.css'
import './styles/carbon.scss'
import clsx from 'clsx'

export const links: LinksFunction = () => [
	{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
	{
		rel: 'preconnect',
		href: 'https://fonts.gstatic.com',
		crossOrigin: 'anonymous'
	},
	{
		rel: 'stylesheet',
		href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300..600&display=swap'
	}
]

export const webpages = [
	'/',
	'/terms',
	'/privacy',
	'/cookies',
	'/release-notes',
	'/onboarding'
]

export function Layout({ children }: { children: React.ReactNode }) {
	const location = useLocation()

	// Not sure if results page scrolling is in its final form, so now the whole page is scrollable
	const scrollable =
		webpages.includes(location.pathname) ||
		location.pathname.startsWith('/results') ||
		location.pathname.startsWith('/workspace')

	return (
		<html lang='en'>
			<head>
				<meta charSet='utf-8' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1'
				/>
				<meta name='google' content='notranslate' />
				<meta httpEquiv='Content-Language' content='en'></meta>
				<Meta />
				<Links />
			</head>

			<body
				className={clsx(
					'm-0 p-0',
					'flex flex-col',
					'h-screen max-h-screen min-h-screen',
					'bg-background text-text-primary',
					scrollable && 'overflow-y-auto overflow-x-hidden',
					!scrollable && 'fixed inset-0 overflow-hidden'
				)}
			>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	)
}

function App() {
	return <Outlet />
}

export default withSentry(App)

export function ErrorBoundary() {
	const error = useRouteError() as Error

	captureRemixErrorBoundaryError(error)

	return (
		<html lang='en'>
			<head>
				<meta charSet='utf-8' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1'
				/>
				<meta name='google' content='notranslate' />
				<meta httpEquiv='Content-Language' content='en'></meta>
				<Meta />
				<Links />
			</head>
			<body className='m-0 flex min-h-screen flex-col bg-background p-0 text-text-primary'>
				<Navbar />
				<WebHeader
					title={<>{error?.cause ?? 'Something went wrong'}</>}
					description={
						<>{error?.message ?? 'Something went wrong'}</>
					}
				/>
			</body>
		</html>
	)
}
